.credits {
    list-style-type: none;
    margin-top: 10px;
}

.awardsContainer {
    margin-top: 10px;
}

.awards {
    list-style-type: none;
}

.awards li {
    font-style: italic;
}